import * as React from "react";
import ReactDOM from 'react-dom';
import { SubHeader } from "../../Menu/SubHeader";
import { isNullOrUndefined, isArray, isNull } from "util";
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import { API } from "../../Resources/utility/api/apiCalls";
import { doSearchField, doRemoveItem, doSort } from "../../Resources/utility/sortFilter/sortFilter";
import { isArrayNullOrEmpty } from "../../Resources/utility/verifying/verfyItems";

import apiDefaultSettings from "../../Resources/json/apiCalls.json"


export class APIResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTokenField: null,
            dataJsonTextviewToggle: null,
            jsonValue: null,
            apiExecProgress: null,
            execCount: 0,
            apiHeaders: [],
        };
        this.onSubmitt = this.onSubmit.bind(this);
        //this.toggleData = this.toggleData.bind(this);

    }

    onSubmit(event) {
        //event.preventDefault();
        const { jsonValue, apiHeaders } = this.state;
        // if (this.apiDataType.value == "multiple" && !isArray(this.apiData.value)) {
        //     alert('You have selected "Record Array". This requires data array!');
        //     return;
        // }

        try {
            this.setState({ dataJsonTextviewToggle: null });
            let pastRequests = localStorage['pastRequests'] ? JSON.parse(localStorage['pastRequests']) : [];
            let apiAllData = {};
            apiAllData['apiURL'] = this.apiURL.value;
            apiAllData['apiCallType'] = this.apiCallType.value;
            apiAllData['apiAuthType'] = this.apiAuthType.value;
            apiAllData['apiDataType'] = this.apiDataType.value
            apiAllData['apiData'] = jsonValue
            apiAllData.apiHeaders = apiHeaders;


            let foundAPI = doSearchField(pastRequests, 'apiURL', apiAllData.apiURL);
            if (!isArrayNullOrEmpty(foundAPI)) {
                //foundAPI = apiAllData
                foundAPI = doRemoveItem(pastRequests, 'apiURL', apiAllData.apiURL);
                foundAPI.push(apiAllData);
                localStorage['pastRequests'] = JSON.stringify(foundAPI);
                
            } else {
                if (isArrayNullOrEmpty(pastRequests)) {
                    let apiArray = [];

                    apiArray.push(apiAllData);

                    localStorage['pastRequests'] = JSON.stringify(apiArray);
                } else {
                    pastRequests.push(apiAllData)
                    localStorage.setItem('pastRequests', JSON.stringify(pastRequests));
                }
            }


            try {
                if (jsonValue !== "") {
                    JSON.parse(jsonValue);
                }
            } catch (err) {
                alert('unable to convert text to JSON');
                return;
            }

            this.setState({ apiExecProgress: true })

            

            let SendHeaders = {}

            for (let i = 0; i < apiHeaders.length; i++) {
                SendHeaders[apiHeaders[i].key] = apiHeaders[i].value;
            }
            if (!isNullOrUndefined(this.apiToken)) {
                SendHeaders['Authorization'] = 'Bearer ' + this.apiToken.value;
            }


            if (this.apiDataType.value == "one") {

                API(this.apiURL.value, this.apiCallType.value, jsonValue, this.apiAuthType.value, SendHeaders, 0)
                    .then((result) => {

                        if (!isNullOrUndefined(result)) {
                            let resultIncluded = jsonValue ? JSON.parse(jsonValue) : {};
                            resultIncluded.result = result;
                            this.setState({ jsonValue: JSON.stringify(resultIncluded) })
                        }
                        //alert('setting apiExecProgress to null');
                        this.setState({ apiExecProgress: null });
                    })
                    .catch(rejectedResult => {
                        let resultIncluded = jsonValue ? JSON.parse(jsonValue) : {};
                        resultIncluded.rejected = rejectedResult.message;
                        this.setState({ jsonValue: JSON.stringify(resultIncluded) })

                    }).finally(() => {
                        this.setState({ apiExecProgress: null });
                    })
            }
            if (this.apiDataType.value === "multiple") {

                let localJsonData = JSON.parse(jsonValue);
                let j = 0;
                for (let i = 0; i < localJsonData.length; i++) {
                    API(this.apiURL.value, this.apiCallType.value, JSON.stringify(localJsonData[i]), this.apiAuthType.value, SendHeaders, i)
                        .then(result => {

                            
                            localJsonData[i]['result'] = result;
                            this.setState({ jsonValue: JSON.stringify(localJsonData) });
                            if (j == localJsonData.length - 1) {
                                this.setState({ apiExecProgress: null, execCount: 0, })

                            } else {

                                j++

                                this.setState({ execCount: j })
                            }
                        })
                        .catch(rejectedResult => {
                            localJsonData[i]['rejected'] = rejectedResult;
                            this.setState({ jsonValue: JSON.stringify(localJsonData) });
                            if (j == localJsonData.length - 1) {
                                this.setState({ apiExecProgress: null, execCount: 0, })

                            } else {

                                j++

                                this.setState({ execCount: j })
                            }

                        })
                }



            }
        } catch (err) {
            alert(err.toString());
        }
    }



    authType(event) {
        if (event.currentTarget.value === "token") {
            this.setState({ showTokenField: true });
        } else {
            this.setState({ showTokenField: null });
        }
    }

    toggleData(event) {
        const { dataJsonTextviewToggle, jsonValue } = this.state;
        if (dataJsonTextviewToggle === null) {

            this.setState({ dataJsonTextviewToggle: true });

            if (jsonValue !== "") {
                this.jsoneditor.updateText(jsonValue);
            } else {
                this.jsoneditor.update(null)
            }

        } else {

            this.setState({
                dataJsonTextviewToggle: null,
                jsonValue: JSON.stringify(this.jsoneditor.get()) === 'null' ? "" : JSON.stringify(this.jsoneditor.get()),
            });
        }
    }
    txtDataChange(event) {
        this.setState({ jsonValue: event.currentTarget.value });
    }

    loadPastRequest(event, item) {

        this.apiURL.value = item.apiURL;
        this.apiCallType.value = item.apiCallType;
        this.apiAuthType.value = item.apiAuthType;
        this.apiDataType.value = item.apiDataType;
    
        if (item.apiAuthType === "token") {
            this.setState({ showTokenField: true });
        } else {
            this.setState({ showTokenField: null });
        }
        //this.apiToken.value = item.apiToken;
        this.setState({ jsonValue: item.apiData, apiHeders: item.apiHeaders })
    }

    saveAPIHeader(event, key, value, id) {
        const { apiHeaders } = this.state;
        let apiHeader = {};
        apiHeader.key = key;
        apiHeader.value = value;
        if (isNullOrUndefined(id)) {
            apiHeader.id = apiHeaders.length.toString();
            apiHeaders.push(apiHeader)
            this.setState({ apiHeaders });
            return;
        } else {
            apiHeader.id = id;
            let localapiHeaders = doRemoveItem(apiHeaders, 'id', id)
            localapiHeaders.push(apiHeader);
            localapiHeaders = doSort(localapiHeaders, 'id');
            this.setState({ apiHeaders: localapiHeaders });
            return;
        }

    }
    saveNewHeader(e) {
        if (this.apiNewHeaderKey.value === "") {
            alert('Please Enter API Key');
            return;
        }
        if (this.apiNewHeaderValue.value === "") {
            alert('Please Enter API Key Value');
            return;
        }

        this.saveAPIHeader(null, this.apiNewHeaderKey.value, this.apiNewHeaderValue.value);
        this.apiNewHeaderKey.value = "";
        this.apiNewHeaderValue.value = "";
    }

    ChangeHeaderValues(event, type, id) {
        const { apiHeaders } = this.state

        let currentAPIHeader = doSearchField(apiHeaders, 'id', id);
        if (type === "Key") {
            this.saveAPIHeader(event, event.currentTarget.value, currentAPIHeader[0].value, id);
        } else {
            this.saveAPIHeader(event, currentAPIHeader[0].key, event.currentTarget.value, id);
        }

    }
    DeleteHeaderValue(event, id) {
        const { apiHeaders } = this.state;
        let localHeaders = doRemoveItem(apiHeaders, 'id', id);
        let newLocalHeader = []

        for (let i = 0; i < localHeaders.length; i++) {
            let localHeader = localHeaders[i];
            localHeader.id = i.toString();
            newLocalHeader.push(localHeader);
        }

        this.setState({ apiHeaders: newLocalHeader });

    }

    componentDidMount() {

        this.setState({ apiHeaders: apiDefaultSettings.DefaultHeaders })
        const editorOptions = {
            mode: 'tree',

        };


        this.jsoneditor = new JSONEditor(this.container, editorOptions);

    }


    componentWillUnmount() {
        if (this.jsoneditor) {
            this.jsoneditor.destroy();
        }
    }

    render() {

        const { showTokenField, dataJsonTextviewToggle, jsonValue, apiExecProgress, execCount, apiHeaders } = this.state;
        let pastRequests = localStorage.getItem('pastRequests') ? JSON.parse(localStorage.getItem('pastRequests')) : [];

        return (
            <form ref={form => this.messageForm = form} onSubmit={this.onSubmit.bind(this)} className="needs-validation">
                <div className="container-fluid m-0 p-0">
                    <div className="row no-gutters">
                        <div className="col m-0 p-0">
                            <SubHeader headertxt="Run API Calls" />
                        </div>
                    </div>
                    <div className="row no-gutters align-items-center mt-3">
                        <div className="col-3">
                            <label class="float-right mr-1">API URL:</label>
                        </div>
                        <div className="col-9 ">
                            <input className="pl-2 form-control" type="url" ref={apiURL => this.apiURL = apiURL} placeholder="Please enter API URL including action" required />
                        </div>
                    </div>
                    <div className="row no-gutters align-items-center mt-3">
                        <div className="col-3">
                            <label class="float-right mr-1">Call Type:</label>
                        </div>
                        <div className="col-9">
                            <select className="form-control" style={{ width: 'auto' }} ref={apiCallType => this.apiCallType = apiCallType} required>
                                <option value="" selected disabled>Please Select One</option>
                                <option value="get">Get</option>
                                <option value="post">Post</option>
                                <option value="put">Put</option>
                            </select>
                        </div>
                    </div>
                    <div className="row no-gutters align-items-center mt-3">
                        <div className="col-3">
                            <label class="float-right mr-1">Authentication Type:</label>
                        </div>
                        <div className="col-9 ">
                            <select className="form-control" style={{ width: 'auto' }} ref={apiAuthType => this.apiAuthType = apiAuthType} onChange={e => this.authType(e)} required>
                                <option value="" selected disabled>Please Select One</option>
                                <option value="windows">Windows</option>
                                <option value="token">Token</option>
                            </select>
                            {
                                showTokenField
                                    ? <div className="m-1"><input className="pl-2 w-100 form-control" type="text" ref={apiToken => this.apiToken = apiToken} placeholder="Please Enter your authorization token!" required /> </div>
                                    : null
                            }
                        </div>
                    </div>
                    <div className="row no-gutters align-items-center mt-3">
                        <div className="col-3">
                            <label class="float-right mr-1">Additional Header:</label>
                        </div>
                        <div className="col-9 ">
                            <div className="contrainer-fluid border rounded">
                                <div className="row">
                                    <span className="col">Key</span>
                                    <span className="col">Value</span>
                                    <span className="col">Action</span>

                                </div>
                                {
                                    apiHeaders.map(item => {
                                        return (
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <input className="form-control" onChange={e => this.ChangeHeaderValues(e, 'Key', item.id)} value={item.key} ref={apiHeaderKey => this.apiHeaderKey = apiHeaderKey} />
                                                </div>
                                                <div className="col-4 ">
                                                    <input className="form-control" onChange={e => this.ChangeHeaderValues(e, 'Value', item.id)} value={item.value} ref={apiHeaderValue => this.apiHeaderValue = apiHeaderValue} />
                                                </div>
                                                <div className="col-4">
                                                    <button type="button" onClick={e => this.DeleteHeaderValue(e, item.id)} className="form-control col btn btn-outline-danger">Delete</button>

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="row">
                                    <div className="col-4 "><input className="form-control" ref={apiNewHeaderKey => this.apiNewHeaderKey = apiNewHeaderKey} /></div>
                                    <div className="col-4 "><input className="form-control" ref={apiNewHeaderValue => this.apiNewHeaderValue = apiNewHeaderValue} /></div>
                                    <div className="col-4 "><button type="button" onClick={e => this.saveNewHeader(e)} className="form-control btn btn-outline-primary">Save</button></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row no-gutters align-items-center mt-3">
                        <div className="col-3 ">
                            <label class="float-right mr-1">Data Type:</label>
                        </div>
                        <div className="col-9">
                            <select className="form-control" style={{ width: 'auto' }} ref={apiDataType => this.apiDataType = apiDataType} required>
                                <option value="" selected disabled>Please Select One</option>
                                <option value="one">One Record</option>
                                <option value="multiple">Record Array</option>
                            </select>
                        </div>

                        <div className="col-3 mt-2">
                            <div className="d-inline-block float-right mr-1"><label class="float-right">Data:</label><br />
                                <button type="button" className="btn btn-outline-primary float-right" onClick={e => this.toggleData(e)}>{dataJsonTextviewToggle ? 'Text View' : 'Json View'}</button>
                            </div>
                        </div>
                        <div className="col-9 mt-2">
                            <div className="d-inline-flex" style={{ minHeight: '250px', width: '100%' }}>
                                <div className="jsoneditor-react-container w-100" ref={elem => this.container = elem} hidden={dataJsonTextviewToggle ? false : true} />
                                <textarea className="form-control w-100" ref={jsonObject => this.jsonObject = jsonObject} onChange={e => this.txtDataChange(e)} hidden={dataJsonTextviewToggle ? true : false} value={jsonValue}></textarea>

                            </div>
                        </div>

                        <div className="col-3 mt-2">
                            <label class="float-right mr-1">Commands:</label>
                        </div>
                        <div className="col-9 mt-2">
                            <button className="btn btn-lg btn-primary" ref={apiExec => this.apiExec = apiExec} type="submit" disabled={apiExecProgress ? true : false}>{apiExecProgress ? 'Processing...' : 'Execute'}</button>
                            {execCount === 0 ? null : execCount}
                        </div>

                    </div>
                    <div className="row no-gutters align-items-center mt-3">
                        <div className="col-3">
                            <label class="float-right mr-1">Request Unique History:</label>
                        </div>
                        <div className="col-9 ">
                            {
                                pastRequests.map(item => {
                                    return (<div className="border rounded" >
                                        <span onClick={e => this.loadPastRequest(e, item)}>{item.apiURL}</span>
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </form >
        );
    }

}