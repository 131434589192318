import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Tabs, Tab, TabContainer, TabContent, TabPane, Row, Col, nav, NavItem, Navbar, NavDropdown, MenuItem } from 'bootstrap';
import { Router, NavLink, Link } from 'react-router-dom';



export class Menu extends Component {

  constructor(props) {
    super(props);
    this.setState({ selectedMenu: undefined, selection: false })
    this.shouldSelect = this.shouldSelect.bind(this);

  }

  shouldSelect(event) {


    if (this.state != null) {
      this.state['selectedMenu'].setAttribute('aria-selected', 'false');
      this.state['selectedMenu'].setAttribute('Class', 'nav-link');
    } else {
      ReactDOM.findDOMNode(this.Home).setAttribute('aria-selected', 'false');
      ReactDOM.findDOMNode(this.Home).setAttribute('Class', 'nav-link');

    }
    var newMenuItem = event.target;
    this.setState({ selectedMenu: newMenuItem })
    newMenuItem.setAttribute('aria-selected', 'true');
    newMenuItem.setAttribute('Class', 'nav-link active');

    //Collepsing menu on Mobile Phone
    ReactDOM.findDOMNode(this.expandMenu).setAttribute('aria-expanded', 'false');
    var item = event.target.parentNode.parentNode.parentNode;
    item.setAttribute('class', 'navbar-collapse collapse');
    //  window.location = newMenuItem.href;

  }

  initialSelect() {

    var newMenuItem = this.Home;
    this.setState({ selectedMenu: newMenuItem })
    newMenuItem.setAttribute('aria-selected', 'true');
    newMenuItem.setAttribute('Class', 'nav-link active');

  }

  componentDidMount() {
    this.initialSelect();
  }


  render() {


    return (

      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <button class="navbar-toggler" ref={expandMenu => this.expandMenu = expandMenu} type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0 nav-pills nav-fill">
            <li class="nav-item" >
              <a className="nav-link" ref={Home => this.Home = Home} href="#/Home/Home" onClick={(e) => this.shouldSelect(e)}>Home</a>
            </li>
            <li class="nav-item">
              <a className="nav-link" href="#/Main/XMLUtility" onClick={(e) => this.shouldSelect(e)}>XML</a>
            </li>
            <li class="nav-item">
              <a className="nav-link" href="#v-pills-messages" onClick={(e) => this.shouldSelect(e)}>JSON</a>
            </li>
            <li class="nav-item">
              <a className="nav-link" href="#/Main/UUId" onClick={(e) => this.shouldSelect(e)}>UUID</a>
            </li>
            <li class="nav-item">
              <a className="nav-link" href="#/Main/API" onClick={(e) => this.shouldSelect(e)}>API</a>
            </li>
          </ul>


        </div>
      </nav>





    );

  }
}

