import * as React from "react";
import { Grid, Row, Col } from 'bootstrap';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { GetElement } from "../Main/XMLUtility/GetElement";
import { Menu } from "../Menu/menu";
import { Home } from "../Main/Home/Home";
import { Header } from "../Menu/Header";
import { UUId } from "../Main/UUID/UUId";
import { APIResults } from "../Main/API/APIResults";






export class MainLayout extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <HashRouter>
                <div class="container-fluid h-100">
                    <div class="container-fluid m-0 p-0">
                        <div className="row">
                            <div className="col">
                                <Header />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Menu />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col">
                                <Switch>
                                    <Route path={'/Main/XMLUtility'} component={GetElement} />)}/>
                            <Route path={'/Main/UUId'} component={UUId} />)}/>
                            <Route path={'/Main/API'} component={APIResults} />
                                    <Route path="/*" component={Home} />
                                </Switch>
                            </div>
                        </div>


                    </div>
                </div>
            </HashRouter>

        );

    }
}
