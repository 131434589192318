

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {MainLayout} from './Layout/mainLayout'
import './index.css';


ReactDOM.render(<MainLayout />, document.getElementById('root'));

