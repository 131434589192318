import * as React from "react";
import ReactDOM from 'react-dom';

export class Home extends React.Component {

    render() {
        return (
            <div class="container">
                <div>This is Home Page</div>
            </div>
            );
    }

}