import * as React from "react";
import ReactDOM from 'react-dom';
import logoShield from '../Resources/Images/logo-shield.svg'

export class Header extends React.Component
{

    render()
    {
        return (
            <div >
               
                <div class="header m-0 pt-2 pb-2 bg-dark text-white">
                    <span class="p-2"></span><img  src={logoShield} alt={'img'} width="20px"
                        height="20px" /> <span class="p-3">Parapet Utility</span>
                            </div>
            </div>
        );
    }

}