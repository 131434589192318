import * as React from "react";

import { isNullOrUndefined } from "util";

export class SubHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headertxt: props.headertxt
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ headertxt: nextProps.headertxt });
    }

    render() {

        let { headertxt } = this.state;

        //this.state['headertxt'] ? this.state['headertxt'] : "";
        return (
            <div class="m-0 pl-3 bg-dark text-white">
                {headertxt ? headertxt : ""}
            </div>
        );
    }

}