import * as React from "react";
import ReactDOM from 'react-dom';
import { table, tbody, tr, td, button, textarea } from 'bootstrap';
import { v4 } from 'uuid';
import { SubHeader } from "../../Menu/SubHeader";

export class UUId extends React.Component {
    constructor(props) {
        super(props);

        this.onSubmitt = this.onSubmit.bind(this);

    }

    onSubmit(event) {
        event.preventDefault();
        var int = ReactDOM.findDOMNode(this.Numb).value;
        var uuids = [];


        for (var i = 0; i < int; i++) {
            uuids.push(v4());
        }

        ReactDOM.findDOMNode(this.output).value = uuids.toString().split(",").join("\n");

    }



    render() {
        return (
            <div>
                <SubHeader headertxt="Crete multiple Unique Universal ID." />
                <form ref={form => this.messageForm = form} onSubmit={this.onSubmit.bind(this)} className="needs-validation">
                    <table class="table table-borderless">

                        <tbody >
                            <tr class="row m-0">
                                <td class="col-3" ><label class="float-right">UUID Required:</label></td>
                                <td class="col-9">
                                    <div class="form-group" >
                                        <input type="number" ref={Numb => this.Numb = Numb} placeholder="Please Enter Number of UUID required..." class="form-control is-valid" min="1" step="1" data-error-msg="The Whole Number is required!" required />
                                    </div>

                                </td>
                            </tr>
                            <tr class="row m-0">
                                <td class="col-12" >
                                    <div class="form-group row justify-content-center" >
                                        <button class="btn btn-lg btn-outline-primary align-middle" type="submit">Create</button>
                                    </div>
                                </td>
                            </tr>

                            <tr class="row m-0" >
                                <td class="col-3" ><label class="float-right">Created UUID:</label></td>
                                <td class="col-9">
                                    <div class="form-group shadow-textarea" >
                                        <textarea class="form-control animated" type="text" ref={output => this.output = output} placeholder="UUID Will display Here" class="form-control" />
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </form>
            </div>
        );
    }

}