import { isNullOrUndefined, isArrayNullOrEmpty } from "../verifying/verfyItems";

export function doSort(obj, sortFieldName, isAscending) {



    if (isNullOrUndefined(obj)) return null;
    if (isNullOrUndefined(sortFieldName)) return obj;

    let sortField = sortFieldName ? sortFieldName : 'priority';
    let sortOrder = isAscending ? isAscending : true;

    obj.sort((a, b) => {
        return a[sortField] > b[sortField] ? 1 : a[sortField] < b[sortField] ? -1 : 0;
    });

    return obj;

}

export async function doSortFilter(data, filterAndSortObj) {
    if (isNullOrUndefined(data)) return null;

    let output = data;
    let filterScopeAr, filterPhrase, sortBy;
    let sortField = 'priority';
    if (!isNullOrUndefined(filterAndSortObj)) {
        filterScopeAr = (filterAndSortObj.type && filterAndSortObj.type.length > 0) ? (JSON.parse("[\"" + filterAndSortObj.type + "\"]")) : [];
        filterPhrase = filterAndSortObj.phrase;
        sortBy = filterAndSortObj.sort;
        if (isNullOrUndefined(sortBy)) { sortField = sortBy }
        if (!isNullOrUndefined(filterPhrase) && filterPhrase.length > 0) {
            output = output.filter(output => {
                return (output.label.toLowerCase().includes(filterPhrase.toLowerCase())) ||
                    (output.description.toLowerCase().includes(filterPhrase.toLowerCase()))

            });
        }
        if (filterScopeAr.length > 0) {
            output = output.filter(output => {
                return (output.type && (JSON.stringify(filterScopeAr).toLowerCase().includes(output.type.toLowerCase())))

            });
        }
        //console.log("Do Filter : " + JSON.stringify(output));
    }
    return doSort(output, sortField, true);
}
export function doFilterType(dataForFilter, filter) {
    if (isNullOrUndefined(dataForFilter)) return null;

    let output = dataForFilter.filter(data => {
        return (data.type && (JSON.stringify(filter).toLowerCase().includes(data.type.toLowerCase())))
    });
    return output;
}

export function doRemoveItem(data, field, criteria) {
    //console.log('doRemoveItem: in' + JSON.stringify(data))
    if (isNullOrUndefined(data)) return null;
    let output = data.filter(dataItem => {
        //console.log('doRemoveItem: in Let ' + JSON.stringify(dataItem))
        //console.log('doRemoveItem: in Let ' + criteria)
        if (isNullOrUndefined(dataItem[field])) {
            return dataItem;
        }
        if (isNullOrUndefined(criteria) && !isNullOrUndefined(dataItem[field])) {
            return;
        }

        return (!dataItem[field].toString().toLowerCase().includes(criteria.toString().toLowerCase()));
    });
    //console.log('doRemoveItem: out' + JSON.stringify(output));
    return output;
}

export function doRemoveField(data, fieldName) {
    let output = [];
    data.map(item => {
        delete item[fieldName];
        output.push(item);
    });
    return output;
}

function doSearch(dataForSearch, filter) {

    if (isNullOrUndefined(dataForSearch)) return null;
    let output = dataForSearch.filter(data => {
        return (data.label.toLowerCase().includes(filter.toLowerCase()) || data.description.toLowerCase().includes(filter.toLowerCase()));
    });
    return output;
}

export function doSearchField(dataForSearch, field, criteria) {
    //console.log('doSearchField: in' + JSON.stringify(dataForSearch))
    if (isNullOrUndefined(dataForSearch) || !Array.isArray(dataForSearch) || dataForSearch.length < 1) return null;

    let output = dataForSearch.filter(data => {
        ////console.log( 'doSearchField: in Let ' + JSON.stringify(data) )
        ////console.log( 'doSearchField: in Let criteria: ' + criteria )
        if (!isNullOrUndefined(data[field])) {
            return (data[field].toLowerCase().includes(criteria.toLowerCase()));
        }
    });
    ////console.log( 'doSearchField: out' + JSON.stringify( output ) );
    return output;

}

export function doSearchExactField(dataForSearch, field, criteria) {
    //console.log('doSearchField: in' + JSON.stringify(dataForSearch))
    if (isNullOrUndefined(dataForSearch) || !Array.isArray(dataForSearch) || dataForSearch.length < 1) return null;

    let output = dataForSearch.filter(data => {
        ////console.log( 'doSearchField: in Let ' + JSON.stringify(data) )
        ////console.log( 'doSearchField: in Let criteria: ' + criteria )
        if (!isNullOrUndefined(data[field])) {
            return (data[field] === criteria);
        }
    });
    ////console.log( 'doSearchField: out' + JSON.stringify( output ) );
    return output;

}


export function sortFilterSearch(data, sortField, filterTypes, searchCriteria) {
    var output = null;

    if (isNullOrUndefined(data)) return null;
    output = sortField ? doSort(data, sortField, true) : data;
    output = filterTypes ? doFilterType(output, filterTypes) : output;
    output = searchCriteria ? doSearch(output, searchCriteria) : output;

    return output;
}

export function findValueWithNoField(data, value) {
    if (isNullOrUndefined(data)) return false;

    try {
        if (data.indexOf(value) !== -1)
            return true;
    } catch (e) {
        return false;
    }
    return false;
}

export function doFindItemArrayCriteria(dataForSearch, field, criteriaArray) {

    if (isNullOrUndefined(dataForSearch) || dataForSearch.length == 0) {
        return null;
    }

    let output = [];
    dataForSearch.map(item => {
        if (criteriaArray.includes(item[field])) {
            output.push(item);
        }
    })
    return output;

}

export function doRemoveItemArrayCriteria(dataforSearch, field, criteriaArray) {
    //console.log('something big:  criteria' + criteriaArray.length);
    ////console.log( 'something big:  Before' + data.length );

    var data = dataforSearch;
    criteriaArray.map((criArrayItm) => {

        data.splice(data.findIndex(function (inPut) {
            return inPut[field] == criArrayItm
        }), 1);
    })



    ////console.log( 'something big:  After' + data.length );
    return data;

}

export function createArrayForSingleField(dataforArray, field) {
    let output = [];
    if (!isArrayNullOrEmpty(dataforArray)) {
        dataforArray.map((item, i) => {
            output.push(item[field]);
        });
    }
    return output;
}

export function doRemoveDuplicate(data) {

    if (isNullOrUndefined(data) || !Array.isArray(data)) return null;
    let output = [];
    data.map((item) => {
        let addToOutput = true;
        output.map((returnItem) => {
            if (isEquivalent(item, returnItem)) {
                addToOutput = false;
            }
        })
        if (addToOutput) {
            output.push(item);
        }

    })

    return output;;
}

export function doRemoveJsonDuplicate(data) {

    if (isNullOrUndefined(data) || !Array.isArray(data)) return null;
    let output = [];
    data.map((item) => {
        let addToOutput = true;
        output.map((returnItem) => {
            if (isJsonEquivalant(item, returnItem)) {
                addToOutput = false;
            }
        })
        if (addToOutput) {
            output.push(item);
        }

    })

    return output;;
}

export function isObjectEquivalant(a, b) {
    return isEquivalent(a, b);
}

function isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export function isJsonEquivalant(a, b) {

    try {
        if (JSON.stringify(a) == JSON.stringify(b)) return true;
        else return false;
    } catch (err) {
        return false;
    }

}

export function doFindJsonObjectIndex(jsonArray, jsonObject) {

    try {
        let output = jsonArray.map((item, i) => {
            if (isJsonEquivalant(item, jsonObject)) {
                return i;
            } else { return null; }
        })
        let intrim = output.indexOf(0);
        output = output.filter(Boolean);
        if (!isNullOrUndefined(intrim) && intrim != -1) {
            output.push(intrim);
        }
        return output;
    } catch (err) {
        return null;
    }
}

export function doJsonObjectExsist(jsonArray, jsonObject) {
    let indexes = doFindJsonObjectIndex(jsonArray, jsonObject)
    if (isNullOrUndefined(indexes)) {
        return false;
    } else {
        if (indexes.length == 0) {
            return false;
        } else { return true; }
    }
}

export function doAddFieldAndValue(data, key, value) {
    if (isNullOrUndefined(data) || !Array.isArray(data) || isNullOrUndefined(key)) return null;
    data.map((item) => {
        item[key] = value;
    })
    return data;
}