

export async function API(apiName, callType, data, authentication, headers, index) {


    return new Promise(function (response, reject) {



        try {

            if (authentication.toLowerCase() === "windows") {
                //Windows Authentication requries credentials include Not Token!!
                if (callType.toLowerCase() === "get") {
                    let URL = apiName += `?${data}`
                    fetch(apiName, {
                        method: callType,
                        headers: headers,
                        credentials: 'include',
                    })
                        .then(function (result, rejected) {
                            result.index = index;
                            return (response(result.json()));
                        })
                        .catch(function (rejected) {
                            rejected.index = index;
                            return (reject(rejected)); //JSON.parse('{"' + reject.toString() + '"}');
                        })

                } else {
                    fetch(apiName, {
                        method: callType,
                        headers: headers,
                        credentials: 'include',
                        body: data,
                    })
                        .then(function (result, rejected) {
                            result.index = index;
                            return (response(result.json()));
                        })
                        .catch(function (rejected) {
                            rejected.index = index;
                            return (reject(rejected)); //JSON.parse('{"' + reject.toString() + '"}');
                        })
                }
            } else {
                //If Not Windows requires idToken and do not need to include credentials
                if (callType.toLowerCase() === "get") {
                    let URL = apiName += `?${data}`
                    fetch(apiName, {
                        method: callType,
                        headers: headers,

                    })
                        .then(function (result, rejected) {
                            result.index = index;
                            return (response(result.json()));
                        })
                        .catch(function (rejected) {
                            rejected.index = index;
                            return (reject(rejected)); //JSON.parse('{"' + reject.toString() + '"}');
                        })
                } else {
                    fetch(apiName, {
                        method: callType,
                        headers: headers,
                        body: data,
                    })
                        .then(function (result, rejected) {
                            result.index = index;
                            return (response(result.json()));
                        })
                        .catch(function (rejected) {
                            rejected.index = index;
                            return (reject(rejected)); //JSON.parse('{"' + reject.toString() + '"}');
                        })
                }
            }
        } catch (err) {
            return reject(err);
        }
    });
};



