import * as React from "react";
import ReactDOM from 'react-dom';
import { Col, Row, Grid, textarea, Button, table, tbody, tr, td } from 'bootstrap';
import $ from 'jquery';


import {DOMParser, XMLSerializer, Document} from 'xmldom';
import { isNullOrUndefined } from "util";
import { SubHeader } from "../../Menu/SubHeader";


export class GetElement extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            result: null,
            search: null
        }

    }

    searchElement(e){

       
        if(!isNullOrUndefined(this.state['value']) && !isNullOrUndefined(this.state['search'])){

            
            var xmldoco = new DOMParser().parseFromString(this.state['value']);
            var node = xmldoco.getElementsByTagName( this.state['search'] );
            this.setState({result: node.toString()});
        }
        
    }
    searchbyId( e )
    {
        try
        {
            if ( !isNullOrUndefined( this.state['value'] ) && !isNullOrUndefined( this.state['search'] ) )
            {
                var xmldoco = new DOMParser().parseFromString( this.state['value'] );
                var node = xmldoco.getElementById( this.state['search'] );
                this.setState( { result: node.toString() } );
            }
        } catch ( e )
        {
            this.setState( { result: 'unable to find element based on search criteria!' } );
        }
    }

    
    searchbyAttribute( e )
    {
        var serchedNode = null;
        if ( !isNullOrUndefined( this.state['value'] ) && !isNullOrUndefined( this.state['search'] ) )
        {
            var xmldoco = new DOMParser().parseFromString( this.state['value'] );
            //var search = JSON.parse( this.state['search'] );
            var node = xmldoco.getattri
        } else { return; }
    }

    searchedAtt( nodes, searchCr )
    {
        return null;
    }


    handleChange(event) {
        this.setState({
            value: event.target.value
            
        });
    }
    handleChange2(event) {
        //alert('setting search: ' + event.target.value)
        this.setState({
            search: event.target.value
            
        });
    }

    render(){
        return(
            <div>
                <SubHeader headertxt="Search Node in an Xml Document." />
                <table class="table table-borderless">
                    <tbody >
                        <tr class="row m-0">
                            <td class="col-3" ><label for="exampleFormControlTextarea6" class="float-right">XML:</label></td>
                            <td class="col-9">
                                <div class="form-group shadow-textarea" >
                                    <textarea class="form-control z-depth-1" placeholder="Please Enter XML..." id="exampleFormControlTextarea6" onChange={this.handleChange.bind(this)} value={this.state.value} ></textarea>
                                </div>
                            </td>
                        </tr>
                        <tr class="row m-0">
                            <td class="col-3" ><label for="exampleFormControlTextarea7" class="float-right">Search Criteria:</label></td>
                            <td class="col-9">
                                <div class="form-group shadow-textarea" >
                                    <textarea class="form-control z-depth-1" placeholder="Please Enter Search Criteria. Please use | between two search criteria..." id="exampleFormControlTextarea7" value={this.state.search} onChange={this.handleChange2.bind(this)} />
                                    <div class="btn-toolbar row justify-content-center p-2">
                                        <div class="p-2"><button class="btn btn-primary" onClick={( e ) => this.searchElement( e )}>Search Element </button>&nbsp;&nbsp;</div>
                                        <div class="p-2"><button class="btn btn-primary" onClick={( e ) => this.searchbyId( e )}>Search by Id</button>&nbsp;&nbsp;</div>
                                        <div class="p-2"><button class="btn btn-primary" onClick={( e ) => this.searchbyAttribute( e )}>Search by Attribute</button></div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="row m-0">
                            <td class="col-3"><label for="exampleFormControlTextarea8" class="float-right">Result:</label></td>
                            <td class="col-9">
                                <div class="form-group shadow-textarea" >
                                    <textarea class="form-control z-depth-1" placeholder="Result will be displayed here..." id="exampleFormControlTextarea6" value={this.state.result} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                

               
            </div>
        );
    }
}