
export function isNotNullOrUndifined(obj) {
     try { return !!(obj && obj !== "" && obj !== "undefined") } catch (err) { return false }
}

export function isNullOrUndefined( obj )
{
    try
    {
        if ( obj !== null && obj !== undefined )
        {
            return false;
        }
        return true;
    } catch ( err ) { return false; }
}

export function isArrayNullOrEmpty(arrayObject){
    if(!isNullOrUndefined(arrayObject) && Array.isArray(arrayObject) && arrayObject.length >>0){
        return false;
    }else{return true;}
}